import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useConfidenceUserContext } from "@/app/context";
import {
  CalendarAccountInfo,
  CalendarInfoResponse,
  CalendarProviderName,
  listCalendarsUserCalendarsGet,
  setDefaultCalendarV2UserV2CalendarsDefaultPut,
} from "@/generated";
import { toast } from "@/lib/utils";

type PageContextType = {
  isConnectCallback: boolean; // 是否是从 Google 绑定回来
  calendarAccount: CalendarAccountInfo;
  calendars: CalendarInfoResponse[];
  calendarMapping: Record<string, CalendarInfoResponse>;
  loadingCalendars: boolean;
  defaultId: string | undefined;
  setDefaultId: Dispatch<string>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<boolean>;
  selected: string[];
  toggleSelectId: Dispatch<string>;
  migrate: boolean;
  setMigrate: Dispatch<SetStateAction<boolean>>;
  activateEnable: boolean;
  activating: boolean;
  performActivate: () => Promise<void>;
  isAlreadyActive: boolean;
};
const PageContext = createContext({} as PageContextType);

export function usePageContext() {
  return useContext(PageContext);
}

const MAX_ENABLED = 3;
export default function PageContextProvider({
  children,
  provider,
  isConnectCallback,
}: PropsWithChildren<{ provider: CalendarProviderName; isConnectCallback: boolean }>) {
  const [isEditMode, localSetIsEditMode] = useState(isConnectCallback);
  const [selected, setSelected] = useState<string[]>([]);
  const [migrate, setMigrate] = useState(true);
  const [defaultId, localSetDefaultId] = useState<string>();
  const { user, refresh: refreshUser } = useConfidenceUserContext();
  const calendarAccount = useMemo(() => {
    // user.accounts is always exist
    return user.accounts!.find((c) => c.provider === provider)!;
  }, [user, provider]);

  const controllerRef = useRef<AbortController | null>(null);
  const [loadingCalendars, setLoadingCalendars] = useState(true);
  const [allCalendars, setAllCalendars] = useState<CalendarInfoResponse[]>([]);
  const performLoadCalendars = useCallback(async () => {
    if (controllerRef.current) controllerRef.current.abort();
    controllerRef.current = new AbortController();
    try {
      setLoadingCalendars(true);
      setAllCalendars(await listCalendarsUserCalendarsGet(controllerRef.current.signal));
      setLoadingCalendars(false);
    } catch (e: any) {
      setAllCalendars([]);
      if (e && e.name === "CanceledError") {
        //
      } else {
        setLoadingCalendars(false);
      }
    }
  }, []);

  useEffect(() => {
    performLoadCalendars();
    return () => {
      controllerRef.current?.abort();
    };
  }, [performLoadCalendars]);

  const calendars = useMemo(() => {
    const providerCalendars = allCalendars.filter((c) => c.provider === calendarAccount.provider);
    const enabled: CalendarInfoResponse[] = [];
    const disabled: CalendarInfoResponse[] = [];
    providerCalendars.forEach((cal) => {
      if (cal.is_default) {
        enabled.unshift(cal);
      } else if (cal.is_enabled) {
        enabled.push(cal);
      } else {
        disabled.push(cal);
      }
    });
    return [...enabled, ...disabled];
  }, [allCalendars, calendarAccount]);

  const calendarMapping = useMemo(
    () =>
      calendars.reduce(
        (accu, cur) => {
          accu[cur.id] = cur;
          return accu;
        },
        {} as Record<string, CalendarInfoResponse>,
      ),
    [calendars],
  );

  useEffect(() => {
    // We want make sure `defaultId` is alway one of `selected`
    localSetDefaultId((prevDefault) => {
      if (!selected.length) return undefined;
      if (typeof prevDefault === "undefined" || !selected.includes(prevDefault)) {
        return selected[0];
      }
      return prevDefault;
    });
  }, [selected]);

  const toggleSelectId = useCallback((calId: string) => {
    let isOverLimit = false;
    setSelected((prev) => {
      if (prev.includes(calId)) {
        return prev.filter((c) => c !== calId);
      }
      if (prev.length >= MAX_ENABLED) {
        isOverLimit = true;
        return prev;
      } else {
        return [...prev, calId];
      }
    });
    if (isOverLimit) {
      toast(`You can only enable up to ${MAX_ENABLED} calendars`);
    }
  }, []);

  const setDefaultId = useCallback(
    (calId: string) => {
      if (selected.includes(calId)) {
        localSetDefaultId(calId);
      } else {
        localSetDefaultId(selected[0]);
      }
    },
    [selected],
  );

  const setIsEditMode = useCallback(
    (edit: boolean) => {
      if (edit) {
        localSetIsEditMode(true);
        setSelected(calendars.filter((c) => c.is_enabled).map((c) => c.id));
        localSetDefaultId(calendars.find((c) => c.is_default)?.id);
      } else {
        localSetIsEditMode(false);
      }
    },
    [calendars],
  );

  const isAlreadyActive = useMemo(() => {
    return user.current_account === calendarAccount.provider;
  }, [user.current_account, calendarAccount]);

  const activateEnable = useMemo(() => {
    return !!(selected.length <= MAX_ENABLED && defaultId && selected.includes(defaultId));
  }, [selected, defaultId]);

  const [activating, setActivating] = useState(false);
  const performActivate = useCallback(async () => {
    if (selected.length <= MAX_ENABLED && defaultId && selected.includes(defaultId)) {
      // OK to proceed
    } else {
      return;
    }
    setActivating(true);
    try {
      await setDefaultCalendarV2UserV2CalendarsDefaultPut({
        enabled_calendars: selected.map((cid) => ({
          account_id: calendarAccount.account_name,
          provider: calendarAccount.provider,
          calendar_id: cid,
        })),
        default: {
          account_id: calendarAccount.account_name,
          provider: calendarAccount.provider,
          calendar_id: defaultId,
        },
        move_events: migrate,
      });
      toast("Activated");
      localSetIsEditMode(false);
      refreshUser();
      performLoadCalendars();
    } catch (e) {
      //
    } finally {
      setActivating(false);
    }
  }, [selected, calendarAccount, migrate, defaultId, refreshUser, performLoadCalendars]);

  return (
    <PageContext.Provider
      value={{
        isConnectCallback,
        calendarAccount,
        loadingCalendars,
        calendars,
        calendarMapping,
        defaultId,
        setDefaultId,
        isEditMode,
        setIsEditMode,
        selected,
        toggleSelectId,
        migrate,
        setMigrate,
        activateEnable,
        activating,
        performActivate,
        isAlreadyActive,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}
