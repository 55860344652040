import { CalendarPlus2 } from "lucide-react";
import { PropsWithChildren, ReactNode, useMemo, useState } from "react";

import { CalendarInfoResponse } from "@/generated";

import { SettingGroup, SettingItem, SwitchOn } from "../../../components/settings";

export function ActionButtonItem({
  disabled,
  onClick,
  children,
  icon,
}: PropsWithChildren<{ disabled?: boolean; onClick?: () => void; icon?: ReactNode }>) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="flex flex-row items-center justify-center gap-1 py-3 text-center text-sm disabled:opacity-40"
    >
      <div className="flex w-8 flex-shrink-0 flex-row justify-end text-right">{icon}</div>
      {children}
      <div className="w-8 flex-shrink-0"></div>
    </button>
  );
}

export function IsDefaultTag() {
  return (
    <div className="flex flex-row items-center rounded-md bg-green-700 py-0.5 pl-0.5 pr-2 text-xs text-white">
      <CalendarPlus2 height={12} className="stroke-white" />
      <span className="text-[10px]">Default</span>
    </div>
  );
}

export function CalendarDot({ color }: { color: string }) {
  return (
    <div className="flex h-4 w-4 flex-col items-center justify-center">
      <i className="h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
    </div>
  );
}

export function AllCalendarsTitle() {
  return <p>All Calendars</p>;
}

export function CollapsableReadonlyCalendarListGroup({ calendars }: { calendars: CalendarInfoResponse[] }) {
  const [showAll, setShowAll] = useState(false);
  const suffixNode = <div>You can select multiple calendars</div>;
  const enabledCalendars = useMemo(() => calendars.filter((c) => c.is_enabled), [calendars]);
  const displayCalendars = useMemo(() => {
    return showAll ? calendars : enabledCalendars;
  }, [showAll, calendars, enabledCalendars]);
  return (
    <SettingGroup title={<AllCalendarsTitle />} suffix={suffixNode}>
      {displayCalendars.map((cal) => (
        <SettingItem
          key={cal.id}
          icon={<CalendarDot color={cal.color!} />}
          label={
            cal.is_default ? (
              <div className="flex flex-row items-center gap-2">
                <span>{cal.name}</span>
                {cal.is_default ? <IsDefaultTag /> : null}
              </div>
            ) : (
              <p className={cal.is_enabled ? "" : "opacity-40"}>{cal.name}</p>
            )
          }
          suffix={cal.is_enabled ? <SwitchOn disabled={true} checked={true} /> : null}
        />
      ))}
      {enabledCalendars.length !== calendars.length ? (
        <div className="py-3 text-center text-blue-600 hover:bg-slate-50" onClick={() => setShowAll((p) => !p)}>
          {showAll ? "Only Show Enabled Calendars" : "Show All Calendars"}
        </div>
      ) : null}
    </SettingGroup>
  );
}
