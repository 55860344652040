import { Check, CircleDot, CircleDotDashed, Link2Off, LoaderCircle, TriangleAlert } from "lucide-react";
import { ButtonHTMLAttributes, Dispatch, createElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AppleCalendarIcon, DolaIcon, GoogleCalendarIcon } from "@/app/components/icons";
import { PossibleUserRender, useConfidenceUserContext } from "@/app/context";
import {
  CalendarInfoResponse,
  CalendarProviderName,
  unbindCalendarAccountUserAccountsProviderDelete,
} from "@/generated";
import { cn, defineViewId, toast } from "@/lib/utils";

import {
  HeaderBackButton,
  SettingGroup,
  SettingGroups,
  SettingItem,
  SettingSubpage,
  SwitchOn,
} from "../../../components/settings";
import { CommonLoading } from "../../components";
import { ActionButtonItem, AllCalendarsTitle, CalendarDot, CollapsableReadonlyCalendarListGroup } from "./components";
import PageContextProvider, { usePageContext } from "./context";

function CalendarItem({
  calendar,
  onClick,
  isSelected,
}: {
  calendar: CalendarInfoResponse;
  onClick: Dispatch<string>;
  isSelected: boolean;
}) {
  return (
    <SettingItem
      icon={<CalendarDot color={calendar.color!} />}
      label={calendar.name}
      suffix={<SwitchOn checked={isSelected} onChange={() => onClick(calendar.id)} />}
    />
  );
}

function ReadonlyCalendarListGroup() {
  const { calendars, loadingCalendars } = usePageContext();

  if (!calendars.length) {
    if (loadingCalendars) {
      return (
        <SettingGroup title={<AllCalendarsTitle />}>
          <CommonLoading />
        </SettingGroup>
      );
    }
    return (
      <SettingGroup title={<AllCalendarsTitle />}>
        <div className="py-8 text-center">Empty</div>
      </SettingGroup>
    );
  }
  return <CollapsableReadonlyCalendarListGroup calendars={calendars} />;
}

function EditableCalendarListGroup() {
  const { selected, toggleSelectId, calendars } = usePageContext();
  return (
    <SettingGroup
      title="Select enabled calendars"
      suffix={<div>You can query or update event entries from enabled calendars</div>}
    >
      {calendars.map((cal) => (
        <CalendarItem isSelected={selected.includes(cal.id)} onClick={toggleSelectId} key={cal.id} calendar={cal} />
      ))}
    </SettingGroup>
  );
}

function DefaultCalendarGroup() {
  const { selected, setDefaultId, defaultId, calendarMapping } = usePageContext();
  const selectCalendars = useMemo(() => {
    return selected.map((i) => calendarMapping[i]).filter(Boolean);
  }, [calendarMapping, selected]);

  return (
    <SettingGroup
      title="Choose Default Calendar"
      suffix="Newly created event entries will be added to default calendar."
    >
      {selectCalendars.length ? (
        <>
          {selectCalendars.map((c) => (
            <SettingItem
              icon={<CalendarDot color={c.color!} />}
              onClick={() => setDefaultId(c.id)}
              key={c.id}
              label={c.name}
              suffix={defaultId && defaultId === c.id ? <Check height={16} className="stroke-green-600" /> : null}
            />
          ))}
        </>
      ) : (
        <div className="py-8 text-center text-slate-400">Select enabled calendars first</div>
      )}
    </SettingGroup>
  );
}

function MigrateCheckGroup() {
  const { user } = useConfidenceUserContext();
  const { migrate, setMigrate } = usePageContext();
  if (user.current_account !== CalendarProviderName["self_hosted"]) return null;
  return (
    <SettingGroup
      suffix={
        <div className="flex flex-row items-start gap-2">
          <DolaIcon className="w-6 flex-shrink-0" />
          <span>
            Dola Calendar is your current active calendar. Please note that you will no longer be able to access entries
            in Dola Calendar once you switch to a 3rd party calendar. To migrate existing entries, please select
            "Migrate Existing Entries"
          </span>
        </div>
      }
    >
      <SettingItem
        label="Migrate existing calendar entries"
        suffix={<SwitchOn loading={false} checked={migrate} onChange={setMigrate} />}
      />
    </SettingGroup>
  );
}

function DisconnectActionGroup() {
  const { refresh: refreshUser } = useConfidenceUserContext();
  const { calendarAccount } = usePageContext();
  const go = useNavigate();
  const [disconnecting, setDisconnecting] = useState(false);
  const onDisconnect = useCallback(async () => {
    if (window.confirm("Confirm to disconnect?")) {
      try {
        setDisconnecting(true);
        await unbindCalendarAccountUserAccountsProviderDelete({
          provider: calendarAccount.provider,
        });
        toast("Disconnect");
        refreshUser();
        go(-1);
      } catch (e) {
        toast("Failed to disconnect");
      } finally {
        setDisconnecting(false);
      }
    }
  }, [calendarAccount, refreshUser, go]);

  return (
    <ActionButtonItem
      disabled={disconnecting}
      icon={
        disconnecting ? (
          <LoaderCircle height={16} className="animate-spin stroke-slate-400" />
        ) : (
          <Link2Off height={16} className="stroke-red-500" />
        )
      }
      onClick={onDisconnect}
    >
      <span className="text-red-500">Disconnect</span>
    </ActionButtonItem>
  );
}

function ActivateActionGroup() {
  const { activateEnable, activating, performActivate, isAlreadyActive } = usePageContext();

  const buttonDisabled = useMemo(() => {
    if (!activateEnable) return true;
    return activating;
  }, [activateEnable, activating]);
  return (
    <SettingGroup>
      <ActionButtonItem
        disabled={buttonDisabled}
        onClick={performActivate}
        icon={activating ? <LoaderCircle height={16} className="animate-spin stroke-slate-400" /> : null}
      >
        <span className="text-blue-600">{isAlreadyActive ? "Save" : "Activate"}</span>
      </ActionButtonItem>
    </SettingGroup>
  );
}

function ProviderInfoGroup() {
  const { user } = useConfidenceUserContext();
  const { calendarAccount } = usePageContext();
  const { t: text } = useTranslation("main", { keyPrefix: "CalendarProviderName" });
  const isActive = user.current_account === calendarAccount.provider;
  const statusIcon = isActive ? (
    <CircleDot height={16} className="fill-green-50 stroke-green-700" />
  ) : (
    <CircleDotDashed height={16} className="fill-slate-50 stroke-slate-400" />
  );

  return (
    <SettingGroup
      suffix={
        <div className="flex flex-row items-center gap-1">
          {statusIcon} {text(calendarAccount.provider)}{" "}
          {isActive ? "is your active calendar." : "is not your active calendar. You can activate it in this page."}
        </div>
      }
    >
      <div className="flex flex-row items-center gap-4 p-4">
        {createElement(
          calendarAccount.provider === CalendarProviderName["google"] ? GoogleCalendarIcon : AppleCalendarIcon,
          {
            className: "w-8",
          },
        )}
        <div className="flex-1">
          <div className="text-base">{text(calendarAccount.provider)}</div>
          <div className="text-xs text-slate-400">{calendarAccount.account_name}</div>
        </div>
        <div className="flex-shrink-0">{statusIcon}</div>
      </div>
    </SettingGroup>
  );
}

// TODO open dola app
function OpenDolaAppButton() {
  return (
    <ActionButtonItem>
      <a href="dola://settings">Open Dola App</a>
    </ActionButtonItem>
  );
}

function ContentWithConfidentUser() {
  const { isEditMode, isConnectCallback } = usePageContext();

  return (
    <SettingGroups>
      <ProviderInfoGroup />
      {isEditMode ? (
        <>
          <EditableCalendarListGroup />
          <DefaultCalendarGroup />
          <MigrateCheckGroup />
          <ActivateActionGroup />
          <OpenDolaAppButton />
        </>
      ) : (
        <>
          <ReadonlyCalendarListGroup />
          {isConnectCallback ? <OpenDolaAppButton /> : <DisconnectActionGroup />}
        </>
      )}
    </SettingGroups>
  );
}

function HeaderButton({ className, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button className={cn(className, "text-xs text-blue-600 disabled:opacity-40")} {...rest} />;
}

function PageHeaderRightAction() {
  const { isEditMode, setIsEditMode, loadingCalendars, activating, isAlreadyActive } = usePageContext();
  if (loadingCalendars) return null;
  if (!isEditMode)
    return <HeaderButton onClick={() => setIsEditMode(true)}>{isAlreadyActive ? "Edit" : "Activate"}</HeaderButton>;
  return (
    <HeaderButton disabled={activating} onClick={() => setIsEditMode(false)}>
      Cancel
    </HeaderButton>
  );
}

const VALID_PROVIDERS = [CalendarProviderName["icloud"], CalendarProviderName["google"]];

export const CONNECT_CALLBACK_QUERY_FLAG = "is_connect_callback";

export default function SwitchThirdPartyCalendar() {
  const { t: text } = useTranslation("main", { keyPrefix: "CalendarProviderName" });
  const { provider } = useParams() as { provider: string };
  const [searchParams] = useSearchParams();
  const isConnectCallback = useMemo(() => searchParams.has(CONNECT_CALLBACK_QUERY_FLAG), [searchParams]);

  if (!VALID_PROVIDERS.includes(provider as CalendarProviderName)) {
    return (
      <SettingSubpage header="Error">
        <SettingGroups>
          <SettingGroup>
            <div className="flex flex-col items-center gap-4 py-20">
              <TriangleAlert width={40} height={40} className="stroke-red-700" />
              <div>Not supported provider: {provider}</div>
            </div>
          </SettingGroup>
        </SettingGroups>
      </SettingSubpage>
    );
  }

  return (
    <PossibleUserRender
      loading={
        <SettingSubpage header={<p>{text(provider)}</p>}>
          <SettingGroups>
            <SettingGroup>
              <CommonLoading />
            </SettingGroup>
          </SettingGroups>
        </SettingSubpage>
      }
      user={
        <PageContextProvider isConnectCallback={isConnectCallback} provider={provider as CalendarProviderName}>
          <SettingSubpage
            headerLeft={isConnectCallback ? null : <HeaderBackButton>Calendars</HeaderBackButton>}
            headerRight={isConnectCallback ? null : <PageHeaderRightAction />}
            header={<p style={defineViewId(`setting-calendar-${provider}`)}>{text(provider)}</p>}
          >
            <ContentWithConfidentUser />
          </SettingSubpage>
        </PageContextProvider>
      }
    />
  );
}
